.drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease; /* Smooth movement */
  touch-action: none; /* Prevent default touch actions */
  z-index: 1000;
  /* Remove the transform property */
}

.drawer-content {
  padding: 16px;
}

/* Display only on mobile devices */
@media (max-width: 768px) {
  .drawer {
    display: block; /* Ensure drawer is visible on mobile */
  }
}
